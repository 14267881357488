import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sum, values } from '../../lib/nodash';
import { Box, Text } from 'grommet';

import InfoToolTip from '../InfoToolTip';
import { GALLON_RESTOCK_FEE, SUPPLY_RESTOCK_FEE } from '../../lib/constants';

import {
  hasSupplyItem,
  calculateReturnValues,
  getGallonsReturned,
  calculateRefundValue,
  calculatePaintDiscountFees,
} from '../../lib/orderReturns';
import formatCurrency from '../../lib/formatCurrency';

const OrderReturnRefundBreakdown = ({ order, quantityMap, ...rest }) => {
  const totalProductReturn = useMemo(
    () => sum(values(calculateReturnValues(order, quantityMap))),
    [order, quantityMap]
  );
  const gallonsReturned = useMemo(
    () => getGallonsReturned(order, quantityMap),
    [order, quantityMap]
  );

  const hasSupplies = hasSupplyItem(order, quantityMap);
  const refundAmount = useMemo(
    () => calculateRefundValue(order, quantityMap),
    [order, quantityMap]
  );
  const paintDiscountFees = useMemo(() =>
    calculatePaintDiscountFees(order, quantityMap)
  );

  return (
    <Box {...rest}>
      <Box direction="row" justify="between">
        <Text>Total return value</Text>
        <Text>{formatCurrency(totalProductReturn)}</Text>
      </Box>
      {hasSupplies && (
        <Box direction="row" justify="between">
          <Text>Supply restock fee ($10/order)</Text>
          <Text>-{formatCurrency(SUPPLY_RESTOCK_FEE)}</Text>
        </Box>
      )}
      {gallonsReturned > 0 && (
        <Box direction="row" justify="between">
          <Text>
            Paint restock fee ({formatCurrency(GALLON_RESTOCK_FEE)}/gallon)
          </Text>
          <Text>-{formatCurrency(gallonsReturned * GALLON_RESTOCK_FEE)}</Text>
        </Box>
      )}
      {paintDiscountFees > 0 && (
        <Box direction="row" justify="between">
          <Text>
            Bulk discount adjustment{' '}
            <InfoToolTip
              text={`Your return sets you under the order total threshold for discounts. This is the adjustments on remaining gallons not being returned.`}
            />
          </Text>
          <Text>-{formatCurrency(paintDiscountFees)}</Text>
        </Box>
      )}
      <Box direction="row" justify="between">
        <Text weight={500}>Total refund</Text>
        <Text weight={500}>{formatCurrency(refundAmount)}</Text>
      </Box>
    </Box>
  );
};

OrderReturnRefundBreakdown.propTypes = {
  order: PropTypes.object.isRequired,
  quantityMap: PropTypes.object.isRequired,
};

export default OrderReturnRefundBreakdown;
