import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text, Stack, Select } from 'grommet';
import { m, LazyMotion } from 'framer-motion';

import { isPaint } from '../../lib/product';
import { getLineItemPriceWithOrderDiscount } from '../../lib/orderReturns';
import formatCurrency from '../../lib/formatCurrency';
import useHover from '../useHover';
import ImgixImage from '../ImgixImage';
import InputNumberAdjust from '../Inputs/InputNumberAdjust';

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const OrderReturnSelectItem = ({
  variant,
  image,
  product,
  discountedUnitPrice,
  onChangeQuantity,
  selectedQuantity,
  onChangeReason,
  returnReason,
  maxQuantity,
  error,
  order,
  disabled = false,
}) => {
  const [hovRef, isHovered] = useHover();
  const isActive = selectedQuantity > 0;
  return (
    <LazyMotion strict features={loadFeatures}>
      <Box
        fill="horizontal"
        border={{
          side: 'all',
          color: disabled
            ? 'transparent'
            : isActive
            ? 'black'
            : isHovered
            ? 'light-4'
            : 'transparent',
          size: 'small',
        }}
        ref={hovRef}
        key={variant.id}
        onClick={
          isActive || disabled
            ? null
            : () => onChangeQuantity(variant.id, maxQuantity)
        }
        focusIndicator={false}
        justify="around"
        style={{
          transition: 'border-color 0.4s',
          opacity: disabled ? 0.4 : 1,
        }}
      >
        <Box>
          <Stack>
            {image && (
              <Box>
                <ImgixImage src={image} options={{ w: 300 }} fill />
              </Box>
            )}
            {!disabled && isActive && (
              <m.div
                animate={{ opacity: 1, scale: 1 }}
                initial={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
                style={{ width: '100%', height: '100%' }}
              >
                <Box
                  fill
                  flex={true}
                  justify="start"
                  align="center"
                  background="rgba(255,255,255,0.95)"
                  pad="medium"
                  gap="large"
                  style={{
                    backdropFilter: 'blur(2px)',
                  }}
                >
                  <Box align="center" gap="small">
                    <Text textAlign="center">Return how many?</Text>
                    <Box flex={false}>
                      <InputNumberAdjust
                        quantity={selectedQuantity}
                        onChange={(val) => onChangeQuantity(variant.id, val)}
                        max={maxQuantity}
                      />
                    </Box>
                    <Text color="dark-3" size="small">
                      {formatCurrency(
                        getLineItemPriceWithOrderDiscount(order, {
                          discountedUnitPrice,
                          variant,
                          product,
                        })
                      )}{' '}
                      each
                    </Text>
                  </Box>
                  <Box gap="small">
                    <Text textAlign="center">Reason for returning</Text>
                    <Box
                      height={{ min: 'auto' }}
                      border={{
                        side: 'all',
                        color: error ? 'status-error' : 'transparent',
                      }}
                    >
                      <Select
                        placeholder="Please select one"
                        name="reason"
                        value={returnReason}
                        onChange={({ value }) => {
                          onChangeReason(variant.id, value);
                        }}
                        labelKey="label"
                        valueKey={{
                          key: 'value',
                          reduce: true,
                        }}
                        options={[
                          {
                            value: 'ordered_too_many',
                            label: 'Ordered too many',
                          },
                          { value: 'product_issue', label: 'Product Issue' },
                          {
                            value: 'no_longer_needed',
                            label: 'No Longer Needed',
                          },
                          { value: 'exchange', label: 'Exchange' },
                        ]}
                      />
                    </Box>
                  </Box>
                </Box>
              </m.div>
            )}
          </Stack>
        </Box>
        <Box pad="small">
          <Heading level={5} textAlign="center">
            {product.title} {isPaint(product) && variant.title}
          </Heading>
        </Box>
      </Box>
    </LazyMotion>
  );
};

OrderReturnSelectItem.propTypes = {
  variant: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  onChangeReason: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  selectedQuantity: PropTypes.number,
  discountedUnitPrice: PropTypes.number,
  returnReason: PropTypes.string,
  error: PropTypes.string,
  image: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(OrderReturnSelectItem);
