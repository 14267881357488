import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import {
  filter,
  find,
  get,
  reduce,
  isEqual,
  keys,
  values,
  sum,
  sortBy,
  intersection,
} from '../../lib/nodash';
import { Formik } from 'formik';
import { Button, Box, Grid, Text, ResponsiveContext } from 'grommet';
import * as Yup from 'yup';

import OrderReturnSelectItem from './OrderReturnSelectItem';
import OrderReturnRefundBreakdown from './OrderReturnRefundBreakdown';
import { isReturnable } from '../../lib/product';
import { calculateRefundValue } from '../../lib/orderReturns';
import { PAINT_PRODUCT_TYPES } from '../../lib/productTypes';
import formatCurrency from '../../lib/formatCurrency';

const validationSchema = Yup.lazy((obj) => {
  return Yup.object().shape({
    quantityMap: Yup.object().test(
      'item-min',
      'Please select at least one item to return',
      (v) => {
        return sum(values(v)) > 0;
      }
    ),
    reasonMap: Yup.object(
      reduce(
        (mem, v) => {
          if (obj.quantityMap[v] > 0) {
            mem[v] = Yup.string().required();
          }
          return mem;
        },
        {},
        keys(obj.quantityMap)
      )
    ),
  });
});

const FormOrderReturnSelectItems = ({
  order,
  onSubmit,
  onExchange,
  onProductIssue,
}) => {
  const size = useContext(ResponsiveContext);
  const colCount =
    size === 'small'
      ? 1
      : size === 'medsmall'
      ? 3
      : size === 'medium'
      ? 4
      : size === 'large'
      ? 5
      : 1;

  const getQuantityForVariant = useCallback((id) =>
    get(
      'quantity',
      find((x) => isEqual(id, get('variant.id', x)), order.lineItems) ?? {}
    )
  );

  const initialMap = reduce(
    (mem, x) => {
      mem[x.variant.id] = 0;
      return mem;
    },
    {},
    order.lineItems
  );

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        quantityMap: initialMap,
        reasonMap: {},
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        onSubmit(values.quantityMap, values.reasonMap);
      }}
    >
      {({ setFieldValue, handleSubmit, values, errors, isValid }) => {
        const selectedVariantProductTypes = order.lineItems
          .filter((x) =>
            Object.keys(values.quantityMap)
              .filter((z) => values.quantityMap[z] > 0)
              .includes(x.variant.id)
          )
          .map((x) => x.product.productType);
        const handleQuantityChange = (id, qty) => {
          setFieldValue('quantityMap', {
            ...values.quantityMap,
            [id]: qty,
          });
        };
        const handleChangeReason = (id, reason) => {
          if (reason === 'product_issue') {
            onProductIssue();
          }
          if (reason === 'exchange') {
            onExchange();
          }
          if (reason === 'product_issue') {
            setFieldValue('reasonMap', {
              ...values.reasonMap,
              [id]: '',
            });
          } else {
            setFieldValue('reasonMap', {
              ...values.reasonMap,
              [id]: reason,
            });
          }
        };

        const refundAmount = calculateRefundValue(order, values.quantityMap);
        const returnableItems = filter(
          isReturnable,
          order.lineItems.map((item) => ({
            ...item,
            product: {
              ...item.product,
              variant: item.variant,
            },
          }))
        );

        const hasWallcoverings =
          returnableItems.filter(
            (x) => x.product.productType === 'Wallcoverings'
          ).length > 0;
        const hasWallcoveringsAndOther =
          hasWallcoverings &&
          returnableItems.filter(
            (x) => x.product.productType !== 'Wallcoverings'
          ).length > 0;

        return (
          <form onSubmit={handleSubmit}>
            <Box gap="medium">
              <Box>
                {returnableItems.length === 0 ? (
                  <Text>
                    This order contains no items that can be returned.
                  </Text>
                ) : (
                  <>
                    {hasWallcoveringsAndOther && (
                      <Text>
                        If you are returning Wallcoverings <em>and</em> other
                        items, please complete the process for Wallcoverings
                        first.
                      </Text>
                    )}
                    <Text>Select the items you&apos;d like to return:</Text>
                  </>
                )}
              </Box>
              <Grid columns={{ count: colCount, size: 'auto' }} gap="small">
                {sortBy('product.productType', returnableItems).map((x) => {
                  const disabled =
                    x.product.productType === 'Wallcoverings'
                      ? intersection(
                          selectedVariantProductTypes,
                          PAINT_PRODUCT_TYPES
                        ).length > 0
                      : selectedVariantProductTypes.includes('Wallcoverings');
                  return (
                    <OrderReturnSelectItem
                      key={x.variant.id}
                      {...x}
                      order={order}
                      disabled={disabled}
                      maxQuantity={getQuantityForVariant(x.variant.id)}
                      onChangeReason={handleChangeReason}
                      onChangeQuantity={handleQuantityChange}
                      returnReason={values.reasonMap[x.variant.id]}
                      error={errors.reasonMap && errors.reasonMap[x.variant.id]}
                      selectedQuantity={
                        values.quantityMap && values.quantityMap[x.variant.id]
                          ? values.quantityMap[x.variant.id]
                          : 0
                      }
                    />
                  );
                })}
              </Grid>
              <Box>
                {keys(errors).length > 0 && (
                  <Box pad="medium">
                    {errors.quantityMap && (
                      <Text color="status-error">{errors.quantityMap}</Text>
                    )}
                    {errors.reasonMap && (
                      <Text color="status-error">
                        Please specify a reason for returning each item
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
              <OrderReturnRefundBreakdown
                order={order}
                quantityMap={values.quantityMap}
                width="medium"
                margin="auto"
                border={{ side: 'all', size: 'small', color: 'black' }}
                pad="medium"
              />
              <Box>
                <Button
                  primary
                  type="submit"
                  label={`Continue (${formatCurrency(refundAmount)} refund)`}
                  disabled={!isValid}
                />
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

FormOrderReturnSelectItems.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  order: PropTypes.object,
  onExchange: PropTypes.func.isRequired,
  onProductIssue: PropTypes.func.isRequired,
};

export default FormOrderReturnSelectItems;
